import {
  content,
  contentContainer,
  selectAll,
} from "./styles/kontakt.module.scss";

import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

const Kontakt = ({ data }) => {
  const contact = data.strapiContact?.contact;
  let webLink = data.strapiContact?.web;
  if (!webLink?.includes("https://")) {
    webLink = `https://${webLink}`;
  }
  const emailText = data.strapiContact?.email?.replace("@", "(at)");

  return (
    <div className={`${contentContainer} fade-in`}>
      <ReactMarkdown>{contact}</ReactMarkdown>
      <p className={content}>
        <span className={selectAll}>{data.strapiContact?.web}</span>
        <br />
        <a href={`mailto: ${data.strapiContact?.email}`}>{emailText}</a>
      </p>
      <p className={content}>
        <a
          href={`tel: ${data.strapiContact?.phone}`}
          className="d-inline d-lg-none"
        >
          tel. {data.strapiContact?.phone}
        </a>
        <span className="d-none d-lg-inline">
          tel. <span className={selectAll}>{data.strapiContact?.phone}</span>
        </span>
        <br />
        <a
          href={`tel: ${data.strapiContact?.mobile}`}
          className="d-inline d-lg-none"
        >
          mobil {data.strapiContact?.mobile}
        </a>
        <span className="d-none d-lg-inline">
          mobil <span className={selectAll}>{data.strapiContact?.mobile}</span>
        </span>
        <br />
      </p>
      <p className={content}>
        <a href={data.strapiContact?.instagram} target="_blank">
          bga instagram
        </a>
      </p>
    </div>
  );
};

Kontakt.seoOptions = {
  title: "Kontakt",
};
export default Kontakt;

export const query = graphql`
  query {
    strapiContact {
      contact
      email
      web
      phone
      mobile
      instagram
    }
  }
`;
